$(window).on('load', function() {
    $.ready.then(function() {
        if ($('#loading')) {
            setTimeout(function() {
                $('#loading').fadeOut(600);
            }, 3000);
        }

        if ('#contato' == window.location.hash) {
            $('html, body').scrollTop( $(document).height() );
        }

        if (window.location.hash) {
            $('html, body').scrollTop( $(window.location.hash).offset().top - $('.main-header-bottom').outerHeight() );
        }

        $('.main-header-nav').find('a').on('click', function(e) {
            var $this = $(this);
            var href = $this.attr('href');
            var target = href.replace('#', '');

            if (href.indexOf('#') != -1) {
                e.preventDefault();

                $('html, body').animate({
                    scrollTop: ('contato' == target) ? $(document).height() : $('#'+target).offset().top - $('.main-header-bottom').outerHeight()
                }, 1000);
            }
        });

        $('.scroll-to-top').on('click', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });

        $('.mobile-nav-btn').on('click', function() {
            $('.mobile-header > .mobile-menu').slideToggle();
        });

        // new SmoothScroll(document, 120, 10); // element, distance, smoothness

        var $banner = new Swiper('.swiper-container--banner', {
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 5000
            },
            allowTouchMove: false,
            navigation: {
                prevEl: '.swiper-container--banner-navigation-prev',
                nextEl: '.swiper-container--banner-navigation-next'
            }
        });

        $('.section-banner').hover(function() {
            $banner.autoplay.stop();
        }, function() {
            $banner.autoplay.start();
        });

        // $(window).on('resize', function() {
        //     if ($(document).width() >= 960) {
        //         $('#wrapper').css('margin-bottom', $('.footer').outerHeight());
        //     } else {
        //         $('#wrapper').css('margin-bottom', 0);
        //     }
        // });

        // if ($(document).width() >= 960) {
        //     $('#wrapper').css('margin-bottom', $('.footer').outerHeight());
        // } else {
        //     $('#wrapper').css('margin-bottom', 0);
        // }

        $(window).on('scroll', function() {
            if ($(this).scrollTop() > 35) {
                $('.main-header').addClass('main-header--fixed');
                $('.scroll-to-top').addClass('show-up');
            } else {
                $('.main-header').removeClass('main-header--fixed');
                $('.scroll-to-top').removeClass('show-up');
            }
        });

        if ($(window).scrollTop() > 35) {
            $('.main-header').addClass('main-header--fixed');
            $('.scroll-to-top').addClass('show-up');
        } else {
            $('.main-header').removeClass('main-header--fixed');
            $('.scroll-to-top').removeClass('show-up');
        }

        var $feedbacks = new Swiper('.swiper-container--feedbacks', {
            pagination: {
                el: '.swiper-container--feedbacks-pagination',
                clickable: true,
            },
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 5000
            },
            allowTouchMove: false,
        });

        $('.swiper-container--feedbacks').hover(function() {
            $feedbacks.autoplay.stop();
        }, function() {
            $feedbacks.autoplay.start();
        });

        // Contact form
        var SPMaskBehavior = function(val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('.form-contact').find('input[name="phone"]').mask(SPMaskBehavior, spOptions);

        // $('.form-contact').on('submit', function(e) {
        //     e.preventDefault();

        //     var $this = $(this);

        //     $.ajax({
        //         type: $this.attr('method'),
        //         url: $this.attr('action'),
        //         data: new FormData($this[0]),
        //         cache: false,
        //         processContent: false,
        //         contentType: false,
        //         processData: false,
        //         dataType: false,
        //         beforeSend: function() {
        //             $this
        //                 .find('[type="submit"]')
        //                 .prop('disabled', true)
        //                 .text('Enviando...');
        //         },
        //         success: function(res) {
        //             response = JSON.parse(res);

        //             if (response[0]) {
        //                 $this[0].reset();
        //             }

        //             new NotifyIt({
        //                 // Message to display.
        //                 'message': response[1],
        //                 // Status of the displayed message.
        //                 'status': (response[0]) ? 'success' : 'error',
        //                 // Duration of the alert. 0 to keep it.
        //                 'duration': 5000
        //             });
        //         },
        //         error: function(jqXHR, textStatus, errorThrown) {
        //             new NotifyIt({
        //                 // Message to display.
        //                 'message': errorThrown,
        //                 // Status of the displayed message.
        //                 'status': 'error',
        //                 // Duration of the alert. 0 to keep it.
        //                 'duration': 5000
        //             });
        //         },
        //         complete: function() {
        //             $this
        //                 .find('[type="submit"]')
        //                 .prop('disabled', false)
        //                 .text('Enviar mensagem');
        //         }
        //     });
        // });

        $('.js-ebook__form')
        .add('.form-contact')
        .on('submit', function(e) {
            e.preventDefault();

            var $this = $(this);
            var $submitBtn = $this.find('[type=submit]');
            var submitBtnLabel = $submitBtn.html();

            $.ajax({
                type: $this.attr('method'),
                url: $this.attr('action'),
                data: new FormData($this[0]),
                cache: false,
                processContent: false,
                contentType: false,
                processData: false,
                dataType: false,
                beforeSend: function() {
                    $submitBtn.prop('disabled', true);
                    $submitBtn.text('Aguarde. Enviando...');
                },
                success: function(res) {
                    var response = JSON.parse(res);

                    new NotifyIt({
                        // Message to display.
                        'message': response[1],
                        // Status of the displayed message.
                        'status': (response[0]) ? 'success' : 'error',
                        // Duration of the alert. 0 to keep it.
                        'duration': 5
                    });

                    if (response[0]) {
                        $this[0].reset();
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    new NotifyIt({
                        // Message to display.
                        'message': errorThrown,
                        // Status of the displayed message.
                        'status': 'error',
                        // Duration of the alert. 0 to keep it.
                        'duration': 5
                    });
                },
                complete: function() {
                    $submitBtn.prop('disabled', false);
                    $submitBtn.html(submitBtnLabel);
                }
            })
        });

    });
});
